import React, { useCallback } from 'react'
import { Revision } from '../../../shared/interfaces/project/document/revision/revision.interface'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { useGetUserProfileQuery } from '../../../redux/api-slice'
import { ProjectDocument } from '../../../shared/interfaces/project/document/document.interface'
import {
  selectCurrentCommentDocuments,
  selectCurrentProject,
} from '../../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  setSelectedRevision,
  setSelectedRevisionEdit,
} from '../../../redux/viewer-slice'
import { useDeleteRevisionMutation } from '../../../redux/api/api-revisions-slice'
import { ActionIcon, Menu } from '@mantine/core'

interface CommentCardPopoverProps {
  revision: Revision
  selectedDocument?: ProjectDocument | null
  disabled?: boolean
  setEditRevision: React.Dispatch<React.SetStateAction<boolean>>
  onRevisionChange?: (page: number) => void
  source: 'comment_table' | 'document_viewer'
}

const CommentCardPopover: React.FC<CommentCardPopoverProps> = ({
  selectedDocument,
  revision,
  disabled = false,
  setEditRevision,
  onRevisionChange,
  source,
}) => {
  const currentProject = useSelector(selectCurrentProject)
  const [deleteRevision] = useDeleteRevisionMutation()
  const dispatch = useDispatch()
  const currentCommentDocuments = useSelector(selectCurrentCommentDocuments)
  const { data: user } = useGetUserProfileQuery(undefined)

  const deleteSelectedRevision = useCallback(
    (e) => {
      e.stopPropagation()
      deleteRevision({
        ...revision,
        documents: currentCommentDocuments?.length
          ? currentCommentDocuments.map((d) => d.id)
          : undefined,
        project: !selectedDocument ? currentProject?.id : undefined,
      })
        .unwrap()
        .then(() => {
          dispatch(setSelectedRevision(null))
          dispatch(setSelectedRevisionEdit(false))
        })
    },
    [
      currentCommentDocuments,
      currentProject?.id,
      deleteRevision,
      dispatch,
      revision,
      selectedDocument,
    ]
  )

  const editSelectedRevision = useCallback(
    (e) => {
      e.stopPropagation()
      setEditRevision(true)
      dispatch(setSelectedRevision(revision))
      if (onRevisionChange) {
        onRevisionChange(revision.page ?? 1)
      }
    },
    [dispatch, revision, setEditRevision, onRevisionChange]
  )

  return user?.id === revision.user_created?.id ? (
    <Menu>
      <Menu.Target>
        <ActionIcon tabIndex={-1} className="relative flex items-center justify-center" disabled={disabled} variant="subtle" size="sm" color="gray" data-playwright={`comment_card_options_${source}_${revision?.id}`}>
          <EllipsisVerticalIcon className="h-5 w-5" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item onClick={editSelectedRevision}>
          Edit
        </Menu.Item>
        <Menu.Item onClick={deleteSelectedRevision}>
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  ) : null
}

export default CommentCardPopover
