import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { usePostHog } from 'posthog-js/react'
import { toast } from 'react-toastify'
import { groupBy } from 'lodash'

// Redux
import {
  selectCurrentProject,
  selectDefaultPositionFilter,
  selectSupplementaryConditionsAreProcessing,
  setDefaultPositionFilter,
} from '../../../../redux/application-slice'
import {
  useCreateRiskExportMutation,
  useGetDocumentsListByProjectQuery,
  useGetUserProfileQuery,
} from '../../../../redux/api-slice'
import {
  useGetProjectRiskQuery,
  useGetProjectRisksProcessingStatusQuery,
} from '../../../../redux/api/project-risk-api-slice'
import { useGetRiskCategoriesQuery } from '../../../../redux/api/qa-api-slice'
import {
  RiskWorkflowFilterState,
  selectRiskWorkflowFilterState,
  setRiskWorkflowFilterState,
} from '../../../../redux/workflow-slice'
import {
  selectRiskListSearchQuery,
  selectSelectedCategoryForRiskList,
  selectSelectedDocuments,
  setRiskListSearchQuery,
  setSelectedCategoryFilter,
  setSelectedDocuments,
  setSelectedRiskList,
} from '../../../../redux/risks-search-slice'

// Components
import RiskReviewCard from '../risk-review-card/risk-review-card'
import { WorkflowFilter } from '../../workflow-components/filter-display'
import { RiskReviewSectionSource } from '../risk-review-card/risk-review-source'

// Mantine components
import {
  Skeleton,
  Tooltip as MantineTooltip,
  Loader,
  Popover,
  ThemeIcon,
  Divider,
  Text,
} from '@mantine/core'

// Icons
import {
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'

// Interfaces and types
import {
  ProjectRisk,
  RiskCategory,
} from '../../../../shared/interfaces/project/risk/risk-inteface'

// Constants and utilities
import { POSTHOG } from '../../../../utils/posthog-constants'

// Hooks
import { useTabWidth } from '../../../../hooks/use-tab-width'

// Third-party
import { Tooltip } from 'react-tooltip'
import { RiskList } from '../../../../shared/interfaces/project/risk/risk-list-interface'
import { ProjectDocumentMetadata } from '../../../../shared/interfaces/project/document/document.interface'
import { RiskListHeader } from './risk-list-header'
import { MemoizedNoProjectRisks } from './no-projects-risks-page'
import { RiskAnalysingBecauseSCsBanner } from '../../../banners/specific-banners'

export const useCategoryIdToNameMapping = ({
  riskCategoryId,
}: {
  riskCategoryId: string
}): string => {
  const currentProject = useSelector(selectCurrentProject)
  // TODO put this payload in Redux, so we dont have to fetch it every time a category is displayed (ouch)
  const { data: riskCategories } = useGetRiskCategoriesQuery(
    currentProject?.uuid ?? ''
  )

  if (!riskCategories) {
    return ''
  }

  const topLevelMapping = riskCategories.reduce(
    (acc, category) => {
      if (category.id && category.name) {
        acc[category.id] = category.name
      }
      return acc
    },
    {} as Record<string, string>
  )
  return topLevelMapping[riskCategoryId]
}

const ProcessingRisksNotice = React.memo(
  ({
    memoizedDocumentProcessingCount,
    risksBeingAnalysed,
  }: {
    memoizedDocumentProcessingCount: number
    risksBeingAnalysed: number
  }) => {
    const processingText = useMemo(() => {
      const documentText =
        memoizedDocumentProcessingCount > 0
          ? `${memoizedDocumentProcessingCount} ${memoizedDocumentProcessingCount === 1 ? 'document' : 'documents'}`
          : ''
      const riskText =
        risksBeingAnalysed > 0
          ? `${risksBeingAnalysed} ${risksBeingAnalysed === 1 ? 'risk' : 'risks'}`
          : ''
      const andText = documentText && riskText ? ' and ' : ''
      return `${documentText}${andText}${riskText}`
    }, [memoizedDocumentProcessingCount, risksBeingAnalysed])

    const isPlural = memoizedDocumentProcessingCount + risksBeingAnalysed > 1

    const memoizedLoader = useMemo(
      () => (
        <ThemeIcon color="#FAB0054D" radius="xl" size="xl" className="mr-6">
          <Loader size="46px" className="h-6 w-6" color="yellow" />
        </ThemeIcon>
      ),
      []
    )

    return (
      <div className="h-full p-4 pr-5 pt-3">
        <div className="flex h-full flex-col items-center justify-center px-16 text-center text-xl text-black">
          <div className="flex flex-col items-center justify-center whitespace-nowrap px-6 py-4 text-2xl font-medium">
            <div className="flex flex-row items-center">
              {memoizedLoader}
              <div className="flex flex-col text-left">
                <div>Risk Identification in Progress</div>
                {processingText && (
                  <div className="mt-2 text-sm font-normal text-gray-500">
                    {`${processingText} ${isPlural ? 'are' : 'is'} being processed`}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="absolute bottom-10 mt-2 text-sm font-normal text-gray-500">
            This may take a few minutes depending on project size. <br />
            We will send you an email once all risks are finished processing.
          </div>
        </div>
      </div>
    )
  }
)

const CategorySourcesDropdown = ({
  categorySources,
  selectedDocuments,
  documents,
}: {
  categorySources: any[] // TODO: Define proper type
  selectedDocuments: ProjectDocumentMetadata[]
  documents: ProjectDocumentMetadata[]
}) => {
  const { tabWidth } = useTabWidth()
  const [sourcesExpanded, setSourcesExpanded] = useState(false)

  const onClickSources = () => {
    setSourcesExpanded(!sourcesExpanded)
  }
  return (
    <Popover position="bottom-end" offset={12} shadow="lg">
      <Popover.Target>
        <MantineTooltip
          label={`Beta feature: Sections contain sources that provide context for the overall topic. Click to see the sources for this section.`}
          multiline
          w="380px"
          offset={10}
          position="left"
          onClick={onClickSources}
        >
          <div className="hover:text-500 mr-2 flex cursor-pointer items-center justify-start transition-colors hover:text-blue-500 hover:underline">
            <span className="text-md font-normal">Section Sources</span>
            {sourcesExpanded ? (
              <ChevronUpIcon className="ml-1 h-4 w-4" />
            ) : (
              <ChevronDownIcon className="ml-1 h-4 w-4" />
            )}
          </div>
        </MantineTooltip>
      </Popover.Target>
      <Popover.Dropdown
        className={`z-50 mt-3 rounded-xl border border-gray-200 bg-white p-2 hover:border-yellow-500`}
      >
        <div style={{ width: tabWidth - 180 }}>
          <div className="max-h-[450px] overflow-y-auto">
            <div className="mb-2 flex items-center justify-center text-center text-sm font-normal text-gray-500">
              <InformationCircleIcon className="mr-1 h-4 w-4" /> Beta feature:
              This feature is currently in beta, your feedback would be
              appreciated.
            </div>
            {categorySources.length !== 0 ? (
              categorySources.map((source, index) => (
                <div key={`source_${index}`} className="mb-2 last:mb-0">
                  <RiskReviewSectionSource
                    isExpanded={sourcesExpanded}
                    sourceIndex={index}
                    documents={documents}
                    citation={source}
                    citations={categorySources}
                  />
                </div>
              ))
            ) : (
              <div key={`source_still_loading`} className="mb-2 last:mb-0">
                Sources are still loading...
              </div>
            )}
          </div>
        </div>
      </Popover.Dropdown>
    </Popover>
  )
}

const MemoizedProjectRisks = ({
  selectedDocuments,
  riskListSearchQuery,
  currentSort,
  selectedRiskList,
  riskWorkflowFilterState,
  selectedCategory,
  categoryName,
  onExcelExport,
  projectRisks,
  risksToDisplay,
  onExcelPDF,
  riskCategories,
  groupedRisks,
  documents,
}: {
  selectedDocuments: ProjectDocumentMetadata[]
  riskListSearchQuery: string
  currentSort: string
  selectedRiskList: RiskList
  riskWorkflowFilterState: RiskWorkflowFilterState
  selectedCategory: string | null
  categoryName: string | null
  onExcelExport: () => void
  projectRisks: ProjectRisk[]
  risksToDisplay: ProjectRisk[]
  onExcelPDF: () => void
  riskCategories: RiskCategory[]
  groupedRisks: Record<string, ProjectRisk[]>
  documents: ProjectDocumentMetadata[]
}) => {
  const dispatch = useDispatch()
  const { data: user } = useGetUserProfileQuery(undefined)

  const isCategoryValid = (category: string | null) =>
    category !== '' && category !== null && category !== 'No Section'

  const supplementaryConditionsAreProcessing = useSelector(
    selectSupplementaryConditionsAreProcessing
  )

  return (
    <div className="mr-2 w-full flex-auto space-y-2 overflow-auto p-4 pr-5 pt-3">
      {supplementaryConditionsAreProcessing && (
        <RiskAnalysingBecauseSCsBanner />
      )}

      <RiskListHeader
        selectedDocuments={selectedDocuments ?? []}
        riskListSearchQuery={riskListSearchQuery ?? ''}
        currentSort={currentSort ?? ''}
        selectedRiskList={selectedRiskList ?? null}
        riskWorkflowFilterState={riskWorkflowFilterState}
        selectedCategory={selectedCategory ?? null}
        categoryName={categoryName ?? null}
        onExcelExport={onExcelExport}
        projectRisks={projectRisks ?? []}
        risksToDisplay={risksToDisplay ?? []}
        onExcelPDF={onExcelPDF}
        riskCategories={riskCategories ?? []}
      />
      <Divider color="#d1d1d1" mt="10px" mb="12px" size="xs" w="100%" />
      {/* Risk Card List */}
      <div className="mb-24 flex flex-col space-y-6">
        {Object.entries(groupedRisks).map(([category, risks]) => (
          <div key={category} className="space-y-3">
            <div className="mb-5 mt-2 flex items-center justify-between text-gray-600">
              <div className="flex flex-grow items-center">
                <MantineTooltip
                  label={`See all ${category ?? ''} related risks`}
                  position="right"
                  offset={10}
                  disabled={!isCategoryValid(category)}
                >
                  <Text
                    size="md"
                    fw={500}
                    ml="sm"
                    inherit
                    className={
                      isCategoryValid(category)
                        ? 'cursor-pointer transition-colors hover:text-blue-500 hover:underline'
                        : ''
                    }
                    onClick={() => {
                      if (isCategoryValid(category)) {
                        dispatch(
                          setSelectedCategoryFilter(
                            risks?.[0]?.risk_category?.id ?? null
                          )
                        )
                      }
                    }}
                  >
                    {category}
                  </Text>
                </MantineTooltip>
                <div className="mx-4 flex-grow">
                  <hr className="border-t border-gray-300" />
                </div>
              </div>

              {user?.feature_flags?.category_sources &&
              risks?.[0]?.risk_category?.sources ? (
                <CategorySourcesDropdown
                  categorySources={risks[0].risk_category.sources}
                  selectedDocuments={selectedDocuments ?? []}
                  documents={documents ?? []}
                />
              ) : null}
            </div>
            {Array.isArray(risks) &&
              risks.map((risk) => (
                <RiskReviewCard
                  key={risk.id}
                  documents={documents ?? []}
                  data-testid={risk.id}
                  projectRisk={risk}
                />
              ))}
          </div>
        ))}
      </div>
    </div>
  )
}

ProcessingRisksNotice.displayName = 'ProcessingRisksNotice'

const RiskReviewPage: React.FC = () => {
  const posthog = usePostHog()

  const currentProject = useSelector(selectCurrentProject)
  const riskListSearchQuery = useSelector(selectRiskListSearchQuery)
  const selectedDocuments = useSelector(selectSelectedDocuments)
  const dispatch = useDispatch()

  const [shouldDocumentPoll, setShouldDocumentPoll] = useState(true)
  const supplementaryConditionsAreProcessing = useSelector(
    selectSupplementaryConditionsAreProcessing
  )
  const defaultPositionFilter = useSelector(selectDefaultPositionFilter)

  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken,
    {
      pollingInterval: shouldDocumentPoll ? 10000 : 0,
      refetchOnMountOrArgChange: true,
    }
  )

  const documentProcessingCount = useMemo(() => {
    if (!documents) {
      return 0
    }

    return documents.filter(
      (document) =>
        document.job_status === 'PROCESSING' ||
        document.job_status === 'PENDING'
    ).length
  }, [documents])

  // isFirstTimeProcessing is true when the document processing count is greater than 0. it models
  // the scenario where a user has just created a project, uploaded a document, and is watching the processing state tick down.
  // Because our document processing & risk processing queues are async, we need this to bridge these two states, when risks haven't started processing yet (but are about to).
  const [isFirstTimeProcessing, setIsFirstTimeProcessing] = useState(false)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [transitionTimer, setTransitionTimer] = useState<NodeJS.Timeout | null>(
    null
  )

  useEffect(() => {
    if (documentProcessingCount > 0) {
      setIsFirstTimeProcessing(true)
    } else if (documentProcessingCount === 0 && isFirstTimeProcessing) {
      // Start a transition period when document processing ends
      setShouldDocumentPoll(false)
      setIsTransitioning(true)
      // Set a timeout to end the transition period if risk processing doesn't start
      const timer = setTimeout(() => {
        setIsTransitioning(false)
        setIsFirstTimeProcessing(false)
      }, 10000)
      setTransitionTimer(timer)

      // Ensure the last timer we make is cleaned up
      return () => {
        if (transitionTimer) clearTimeout(transitionTimer)
      }
    }
  }, [documentProcessingCount, isFirstTimeProcessing, transitionTimer])

  const [filter, setFilter] = useState<WorkflowFilter>({})

  const [riskPollingInterval, setRiskPollingInterval] = useState<
    number | undefined
  >(undefined)

  const { currentData: riskPipelineStats } =
    useGetProjectRisksProcessingStatusQuery(currentProject?.uuid ?? skipToken, {
      pollingInterval: 2000,
    })

  const riskWorkflowFilterState = useSelector(selectRiskWorkflowFilterState)

  const {
    data: projectRisks,
    refetch,
    isUninitialized,
    isSuccess,
  } = useGetProjectRiskQuery(
    currentProject?.uuid
      ? {
          projectUUID: currentProject.uuid,
          status: riskWorkflowFilterState?.status ?? undefined,
          sort: riskWorkflowFilterState?.sort ?? '',
        }
      : skipToken,
    {
      pollingInterval: riskPollingInterval,
    }
  )

  useEffect(() => {
    if (riskPipelineStats?.count && riskPipelineStats.count > 0) {
      // Risk processing has started, maintain the transitioning state
      setIsTransitioning(true)
      // Clear any existing transition timer
      if (transitionTimer) {
        clearTimeout(transitionTimer)
        setTransitionTimer(null)
      }
    } else if (riskPipelineStats?.count === 0 && isTransitioning) {
      // Risk processing has ended, but we'll delay ending the transitioning state
      const timer = setTimeout(() => {
        setIsTransitioning(false)
        setIsFirstTimeProcessing(false)
        // Manual refetch is necessary here because:
        // 1. Polling is disabled when processing completes (pollingInterval: undefined)
        // 2. We need a final data refresh after the 2000ms delay to ensure backend processing is complete
        // 3. This handles the specific transition from processing to completed state
        if (!isUninitialized && isSuccess && currentProject?.uuid) {
          refetch()
        }
      }, 2000)
      setTransitionTimer(timer)
    }
  }, [
    riskPipelineStats?.count,
    isTransitioning,
    refetch,
    transitionTimer,
    currentProject?.uuid,
    isUninitialized,
    isSuccess,
  ])

  // Ensure all timers are cleaned up.
  useEffect(() => {
    return () => {
      if (transitionTimer) clearTimeout(transitionTimer)
    }
  }, [transitionTimer])

  const risksBeingAnalysed = useMemo(() => {
    return riskPipelineStats?.count ?? 0
  }, [riskPipelineStats?.count])

  useEffect(() => {
    dispatch(setRiskWorkflowFilterState({ projectUUID: currentProject?.uuid }))
  }, [currentProject?.uuid, dispatch])

  useEffect(() => {
    if (!projectRisks || projectRisks?.some((r) => r.processing_status === 0)) {
      setRiskPollingInterval(5000)
    } else {
      setRiskPollingInterval(undefined)
    }
  }, [projectRisks])

  const selectedRiskList = useSelector(
    (state: any) => state.riskListState.selectedRiskList
  )

  const [createRiskExport] = useCreateRiskExportMutation()
  const [searchParams] = useSearchParams()
  const commentUUID = searchParams.get('comment')
  const { data: riskCategories } = useGetRiskCategoriesQuery(
    currentProject?.uuid ?? ''
  )

  const selectedCategory = useSelector(selectSelectedCategoryForRiskList)

  const onExcelExport = useCallback(() => {
    if (!currentProject?.uuid) {
      return
    }
    toast.success('Exporting Risks as Excel...')
    createRiskExport({
      projectId: currentProject?.uuid,
      type: 'xls',
      documentUuids: selectedDocuments?.length
        ? selectedDocuments.map((d) => d.uuid)
        : undefined,
      riskList: selectedRiskList?.id,
      riskSort: riskWorkflowFilterState?.sort?.toString(),
      searchQuery: riskListSearchQuery,
      categoryId: selectedCategory ?? undefined,
    })
      .unwrap()
      .then((response) => {
        window.open(response.export_url, '_blank')
      })
    posthog?.capture(POSTHOG.export_risk_excel, {
      project_uuid: currentProject?.uuid,
    })
  }, [
    createRiskExport,
    currentProject?.uuid,
    posthog,
    riskListSearchQuery,
    selectedDocuments,
    selectedRiskList?.id,
    riskWorkflowFilterState?.sort,
    selectedCategory,
  ])

  const onExcelPDF = useCallback(() => {
    if (!currentProject?.uuid) {
      return
    }
    toast.success('Exporting Risks as PDF...')
    createRiskExport({
      projectId: currentProject?.uuid,
      type: 'pdf',
      documentUuids: selectedDocuments?.length
        ? selectedDocuments.map((d) => d.uuid)
        : undefined,
      riskList: selectedRiskList?.id,
      riskSort: riskWorkflowFilterState?.sort?.toString(),
      searchQuery: riskListSearchQuery,
      categoryId: selectedCategory ?? undefined,
    })
      .unwrap()
      .then((response) => {
        window.open(response.export_url, '_blank')
      })
    posthog?.capture(POSTHOG.export_risk_pdf, {
      project_uuid: currentProject?.uuid,
    })
  }, [
    createRiskExport,
    currentProject?.uuid,
    posthog,
    riskListSearchQuery,
    selectedDocuments,
    selectedRiskList?.id,
    riskWorkflowFilterState?.sort,
    selectedCategory,
  ])

  useEffect(() => {
    if (!commentUUID) {
      return
    }
    setFilter((f) => {
      return { ...f, comment_uuid: commentUUID }
    })
  }, [commentUUID])

  const filteredRisks = useMemo(() => {
    let risks: ProjectRisk[] = []
    if (!projectRisks) {
      return []
    }
    if (!selectedDocuments?.length) {
      risks = projectRisks
    } else {
      risks = projectRisks?.reduce<ProjectRisk[]>((acc, cur) => {
        const doc_uuids = cur.sources?.map((c) => c.document_segment.document)
        const relevantDocUUIDs = selectedDocuments
          ?.filter((d) => doc_uuids?.includes(d.uuid))
          .map((d) => d.uuid)
        if (relevantDocUUIDs?.length) {
          const filteredSources = cur.sources?.filter((s) =>
            relevantDocUUIDs.includes(
              s.document_segment.document?.toString() ?? ''
            )
          )
          const filteredRisk = {
            ...cur,
            sources: filteredSources,
          }
          return [...acc, filteredRisk]
        }
        return acc
      }, [])
    }
    if (defaultPositionFilter) {
      return risks.filter((r) => {
        return (
          (r?.default_position_met === true &&
            defaultPositionFilter === 'MET') ||
          (r?.default_position_met === false &&
            defaultPositionFilter === 'NOT_MET') ||
          (r?.default_position_met === null &&
            r.default_position_explanation &&
            defaultPositionFilter === 'INDETERMINATE')
        )
      })
    }
    return risks
  }, [projectRisks, selectedDocuments, defaultPositionFilter])

  const risksToDisplay = useMemo(() => {
    if (filter?.comment_uuid) {
      return filteredRisks?.filter((r) => {
        return r?.comments?.some((c) => c?.id === filter.comment_uuid)
      })
    }
    if (!selectedRiskList && !riskListSearchQuery && !selectedCategory) {
      return filteredRisks
    }
    const risks = selectedRiskList
      ? filteredRisks?.filter((r) => {
          const riskListIds = r.risk_lists?.map((rl) => rl.risk_list_template)
          if (!riskListIds) {
            return false
          }
          return riskListIds.includes(selectedRiskList?.id)
        })
      : filteredRisks
    if (selectedCategory !== '' && selectedCategory !== null) {
      return risks.filter((r) => {
        return r?.risk_category?.id === selectedCategory
      })
    }
    if (riskListSearchQuery) {
      return risks.filter((r) => {
        return r?.risk_name
          ?.toLowerCase()
          .includes(riskListSearchQuery.toLowerCase())
      })
    }

    return risks
  }, [
    selectedRiskList,
    riskListSearchQuery,
    filter,
    filteredRisks,
    selectedCategory,
  ])

  // This is an exact copy of the handleResetSortAndFilter in risk-review-card.tsx
  const handleResetSortAndFilter = useCallback(() => {
    const { status, sort, ...rest } = riskWorkflowFilterState
    dispatch(
      setRiskWorkflowFilterState({
        status: undefined,
        sort: '',
        ...rest,
      })
    )
  }, [dispatch, riskWorkflowFilterState])

  // This is an exact copy of the handleResetAllFilters in risk-review-card.tsx
  const handleResetAllFilters = () => {
    dispatch(setDefaultPositionFilter(null))
    dispatch(setRiskListSearchQuery(''))
    dispatch(setSelectedRiskList(null))
    dispatch(setSelectedDocuments([]))
    dispatch(setSelectedCategoryFilter(null))
    handleResetSortAndFilter()
  }

  const ProjectRisksLoading = () => (
    <div className="mt-[70px] flex h-full w-full flex-col items-center justify-center p-4 pr-6">
      <div className="h-full w-full space-y-3 ">
        {Array.from({ length: 7 }).map((_, index) => (
          <Skeleton
            key={index}
            h="112.55px"
            w="100%"
            radius="md"
            className="!border !border-gray-300"
          />
        ))}
      </div>
    </div>
  )

  const currentSort = useSelector(selectRiskWorkflowFilterState)?.sort
  const categoryName = useCategoryIdToNameMapping({
    riskCategoryId: selectedCategory ?? '',
  })

  const groupedRisks = useMemo(() => {
    const grouped = groupBy(
      risksToDisplay,
      (risk) => risk.risk_category?.name || 'No Section'
    )

    // Sort categories alphabetically
    const sortedCategories = Object.keys(grouped).sort((a, b) =>
      a.localeCompare(b)
    )

    // Sort risks within each category by priority
    const sortedGrouped = sortedCategories.reduce((acc, category) => {
      acc[category] = grouped[category].sort(
        (a, b) => (b.status || 0) - (a.status || 0)
      )
      return acc
    }, {})

    return sortedGrouped
  }, [risksToDisplay])

  return (
    <div className="flex h-full flex-col bg-[#e5e7eb]">
      <div className="scrollbar-default scrollbar-thumb-gray-400 scrollbar-track-gray-100 hover:scrollbar-thumb-gray-500 mr-1 flex h-[calc(100%-48px)] flex-col overflow-x-hidden overflow-y-hidden">
        {projectRisks === undefined ? (
          <ProjectRisksLoading />
        ) : isFirstTimeProcessing &&
          (documentProcessingCount > 0 ||
            risksBeingAnalysed > 0 ||
            isTransitioning) ? (
          <ProcessingRisksNotice
            memoizedDocumentProcessingCount={documentProcessingCount}
            risksBeingAnalysed={risksBeingAnalysed}
          />
        ) : projectRisks &&
          risksToDisplay.length === 0 &&
          documentProcessingCount === 0 ? (
          <MemoizedNoProjectRisks
            selectedDocuments={selectedDocuments ?? []}
            riskListSearchQuery={riskListSearchQuery ?? ''}
            currentSort={currentSort ?? ''}
            selectedRiskList={selectedRiskList ?? null}
            riskWorkflowFilterState={riskWorkflowFilterState}
            selectedCategory={selectedCategory ?? ''}
            categoryName={categoryName ?? ''}
            onExcelExport={onExcelExport}
            projectRisks={projectRisks ?? []}
            risksToDisplay={risksToDisplay ?? []}
            onExcelPDF={onExcelPDF}
            riskCategories={riskCategories ?? []}
            handleResetAllFilters={handleResetAllFilters}
            supplementaryConditionsAreProcessing={
              supplementaryConditionsAreProcessing
            }
          />
        ) : (
          <MemoizedProjectRisks
            selectedDocuments={selectedDocuments ?? []}
            riskListSearchQuery={riskListSearchQuery ?? ''}
            currentSort={currentSort ?? ''}
            selectedRiskList={selectedRiskList ?? null}
            riskWorkflowFilterState={riskWorkflowFilterState}
            selectedCategory={selectedCategory ?? ''}
            categoryName={categoryName ?? ''}
            onExcelExport={onExcelExport}
            projectRisks={projectRisks ?? []}
            risksToDisplay={risksToDisplay ?? []}
            onExcelPDF={onExcelPDF}
            riskCategories={riskCategories ?? []}
            groupedRisks={groupedRisks}
            documents={documents ?? []}
          />
        )}
      </div>
      <Tooltip id={'risk-info-id'} style={{ zIndex: 100 }} />
    </div>
  )
}

export default RiskReviewPage
