import React, { useMemo } from 'react'
import { RiskWorkflowFilterState } from '../../../../redux/workflow-slice'
import { ProjectDocumentMetadata } from '../../../../shared/interfaces/project/document/document.interface'
import {
  ProjectRisk,
  RiskCategory,
  RiskStatusIntToString,
} from '../../../../shared/interfaces/project/risk/risk-inteface'
import { RiskList } from '../../../../shared/interfaces/project/risk/risk-list-interface'
import { useTabWidth } from '../../../../hooks/use-tab-width'
import { useElementWidth } from '../../../../hooks/use-element-width'
import clsx from 'clsx'
import { NumberOfRisksPopover } from './number-of-risks-popover'
import { ShareCardPopover } from './share-card-popover'
import { SectionsCardPopover } from './sections-card-popover'
import { SearchFiltersCard } from './search-filters-card'
import { selectDefaultPositionFilter } from '../../../../redux/application-slice'
import { useSelector } from 'react-redux'

export const RiskListHeader = ({
  selectedDocuments,
  riskListSearchQuery,
  currentSort,
  selectedRiskList,
  riskWorkflowFilterState,
  selectedCategory,
  categoryName,
  onExcelExport,
  projectRisks,
  risksToDisplay,
  onExcelPDF,
  riskCategories,
}: {
  selectedDocuments: ProjectDocumentMetadata[]
  riskListSearchQuery: string
  currentSort: string
  selectedRiskList: RiskList | null
  riskWorkflowFilterState: RiskWorkflowFilterState
  selectedCategory: string | null
  categoryName: string | null
  onExcelExport: () => void
  projectRisks: ProjectRisk[]
  risksToDisplay: ProjectRisk[]
  onExcelPDF: () => void
  riskCategories: RiskCategory[]
}) => {
  const defaultPositionFilter = useSelector(selectDefaultPositionFilter)
  const FiltersDisplayStringFormatter = useMemo(() => {
    const parts: string[] = []

    const joinedSelectedDocuments = selectedDocuments
      ?.map((doc) => doc.title)
      .join(', and ')

    if (riskListSearchQuery) {
      parts.push(`Searching for "${riskListSearchQuery}"`)
    }

    if ((selectedDocuments ?? []).length > 0) {
      if (parts.length === 0) {
        parts.push(`In "${joinedSelectedDocuments}"`)
      } else {
        parts.push(`in "${joinedSelectedDocuments}"`)
      }
    }

    if (currentSort) {
      if (parts.length === 0) {
        parts.push(`Sorted by "${currentSort}"`)
      } else {
        parts.push(`sorted by "${currentSort}"`)
      }
    }

    if (selectedRiskList !== null) {
      if (parts.length === 0) {
        parts.push(`Filtered by "${selectedRiskList.name}"`)
      } else {
        parts.push(`filtered by "${selectedRiskList.name}"`)
      }
    }

    if (riskWorkflowFilterState?.status) {
      if (parts.length === 0) {
        parts.push(
          `Filtered by "${RiskStatusIntToString(
            parseInt(riskWorkflowFilterState?.status?.toString() ?? '0')
          )}"`
        )
      } else {
        parts.push(
          `filtered by "${RiskStatusIntToString(
            parseInt(riskWorkflowFilterState?.status?.toString() ?? '0')
          )}"`
        )
      }
    }

    if (selectedCategory) {
      if (parts.length === 0) {
        parts.push(`Filtered by section "${categoryName}"`)
      } else {
        parts.push(`filtered by section "${categoryName}"`)
      }
    }

    if (defaultPositionFilter) {
      parts.push(
        `Filtered by default position "${defaultPositionFilter.replace('_', ' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase())}"`
      )
    }

    return (parts ?? []).join(', ')
  }, [
    selectedDocuments,
    riskListSearchQuery,
    currentSort,
    selectedRiskList,
    riskWorkflowFilterState?.status,
    selectedCategory,
    defaultPositionFilter,
    categoryName,
  ])
  const { tabWidth } = useTabWidth()

  const [shareCardRef, shareCardWidth] = useElementWidth()
  const [filtersCardRef, filtersCardWidth] = useElementWidth()

  const cardsTooWideForPanelWidth =
    shareCardWidth + filtersCardWidth + 24 > tabWidth

  return (
    <div
      className={clsx(
        '-ml-2 grid',
        cardsTooWideForPanelWidth
          ? FiltersDisplayStringFormatter !== ''
            ? 'grid-cols-[1fr] grid-rows-[repeat(2,_1fr)] gap-x-[0px] gap-y-[0px]'
            : 'grid-cols-[1fr] grid-rows-[1fr] gap-x-[0px] gap-y-[0px]'
          : 'grid-cols-[320px_1fr] grid-rows-[1fr] gap-x-[0px] gap-y-[0px]'
      )}
    >
      <div className="flex items-center justify-start [grid-area:1_/_1_/_2_/_2]">
        <div ref={shareCardRef} className="flex items-center">
          <NumberOfRisksPopover
            risksToDisplay={risksToDisplay}
            projectRisks={projectRisks}
          />
          <ShareCardPopover
            projectRisks={projectRisks}
            riskListSearchQuery={riskListSearchQuery}
            risksToDisplay={risksToDisplay}
          />
          <SectionsCardPopover
            selectedCategory={selectedCategory ?? null}
            riskCategories={riskCategories}
          />
        </div>
      </div>

      {FiltersDisplayStringFormatter !== '' ? (
        <div
          className={clsx(
            'flex items-center',
            cardsTooWideForPanelWidth
              ? 'justify-start pl-2 pt-0.5 [grid-area:2_/_1_/_3_/_2]'
              : 'justify-end [grid-area:1_/_2_/_2_/_3]'
          )}
        >
          <div ref={filtersCardRef}>
            <SearchFiltersCard
              defaultText={FiltersDisplayStringFormatter}
              panelWidth={tabWidth}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}
