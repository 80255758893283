import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentProject,
  selectModal,
  setModal,
} from '../../redux/application-slice'
import { useCreateChatFeedbackMutation } from '../../redux/api-slice'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-toastify'
import { useSubmitProjectRiskFeedbackMutation } from '../../redux/api/project-risk-api-slice'
import { Loader } from '@mantine/core'

interface AIChatFeedbackModalProps {
  open: boolean
}

const AIChatFeedbackModal = ({ open }: AIChatFeedbackModalProps) => {
  const [submitting, setSubmitting] = useState(false)
  const [feedback, setFeedback] = useState('')
  const dispatch = useDispatch()
  const posthog = usePostHog()
  const modal = useSelector(selectModal)
  const currentProject = useSelector(selectCurrentProject)
  const [submitChatFeedback] = useCreateChatFeedbackMutation()
  const [submitProjectRiskFeedback] = useSubmitProjectRiskFeedbackMutation()

  const onClose = useCallback(() => {
    dispatch(setModal(null))
    setFeedback('')
    setSubmitting(false)
  }, [dispatch])

  const onSubmit = useCallback(async () => {
    if (submitting) return
    setSubmitting(true)
    try {
      if (modal?.data?.project_risk) {
        await submitProjectRiskFeedback({
          is_positive: modal.data.positive,
          comment: feedback,
          project_risk: modal.data.project_risk,
          feedback_type: modal.data.feedback_type,
        })
      }
      if (modal?.data?.query && modal?.data?.answer) {
        await submitChatFeedback({
          rating_positive: modal.data.positive,
          rating_comment: feedback || null,
          project: currentProject?.id,
          query: modal.data.query,
          answer: modal.data.answer,
        })
        posthog?.capture(POSTHOG.chat_feedback_submitted, {
          project_uuid: currentProject?.uuid,
          rating_positive: modal.data.positive,
        })
      }
      toast.success('Thank you for your feedback!')
      onClose()
    } finally {
      setSubmitting(false)
    }
  }, [
    modal,
    feedback,
    currentProject,
    submitChatFeedback,
    submitProjectRiskFeedback,
    posthog,
    onClose,
    submitting,
  ])

  const feedbackDialogContent = useMemo(
    () => (
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="mb-4 flex items-center justify-center text-lg font-semibold leading-6 text-gray-900"
            >
              Your Feedback:
              {modal?.data?.positive ? (
                <HandThumbUpIcon className="ml-2 h-5 w-5 text-green-500" />
              ) : (
                <HandThumbDownIcon className="ml-2 h-5 w-5 text-red-500" />
              )}
            </Dialog.Title>
            <div className="mt-2">
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && e.ctrlKey && onSubmit()}
                placeholder="Please provide any additional feedback here..."
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                rows={4}
              />
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            onClick={onSubmit}
            disabled={submitting}
          >
            {submitting ? (
              <Loader size="sm" color="yellow" />
            ) : (
              'Submit Feedback'
            )}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    ),
    [feedback, modal, onClose, onSubmit, submitting]
  )

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {feedbackDialogContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AIChatFeedbackModal
