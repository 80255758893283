import React, { useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentProject, setModal } from '../../redux/application-slice'
import { useCreateRiskExportMutation } from '../../redux/api-slice'
import { useGetRiskListFiltersQuery } from '../../redux/api/project-risk-api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { toast } from 'react-toastify'
import clsx from 'clsx'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { RiskList } from '../../shared/interfaces/project/risk/risk-list-interface'
import { POSTHOG } from '../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'

type ExportFormat = 'pdf' | 'xlsx' | 'docx'
type SortOption = 'A-Z' | 'Z-A' | 'Risk Ranking'
type StatusOption = '' | '1' | '2' | '3'

const OptionBox = ({
  selected,
  onClick,
  children,
}: {
  selected: boolean
  onClick: () => void
  children: React.ReactNode
}) => (
  <div
    onClick={onClick}
    className={clsx(
      'cursor-pointer px-3 py-2 text-sm transition-colors',
      selected ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50'
    )}
  >
    {children}
  </div>
)

const FilterSection = ({
  label,
  children,
  maxHeight,
}: {
  label: string
  children: React.ReactNode
  maxHeight?: string
}) => (
  <div className="flex">
    <div className="w-20 shrink-0 text-sm font-medium text-gray-900">
      {label}
    </div>
    <div
      className={clsx(
        'flex-1 rounded-md border',
        maxHeight && 'overflow-y-auto'
      )}
    >
      <div className={clsx('flex flex-col', maxHeight && `max-h-${maxHeight}`)}>
        {children}
      </div>
    </div>
  </div>
)

const ModalTitle = () => (
  <Dialog.Title
    as="h3"
    className="flex items-center text-lg font-semibold leading-6 text-gray-900"
  >
    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
      <ArrowDownTrayIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
    </div>
    <div className="ml-2 font-medium">Export Risk Review</div>
  </Dialog.Title>
)

const FormatOptions = ({
  format,
  setFormat,
}: {
  format: ExportFormat
  setFormat: (format: ExportFormat) => void
}) => (
  <FilterSection label="Format">
    <div className="flex flex-col">
      <OptionBox selected={format === 'pdf'} onClick={() => setFormat('pdf')}>
        PDF
      </OptionBox>
      <OptionBox selected={format === 'xlsx'} onClick={() => setFormat('xlsx')}>
        Excel
      </OptionBox>
      <OptionBox selected={format === 'docx'} onClick={() => setFormat('docx')}>
        Word
      </OptionBox>
    </div>
  </FilterSection>
)

const SortOptions = ({
  sort,
  setSort,
}: {
  sort: SortOption
  setSort: (sort: SortOption) => void
}) => (
  <FilterSection label="Sort">
    <div className="flex flex-col">
      <OptionBox selected={sort === 'A-Z'} onClick={() => setSort('A-Z')}>
        A-Z
      </OptionBox>
      <OptionBox selected={sort === 'Z-A'} onClick={() => setSort('Z-A')}>
        Z-A
      </OptionBox>
      <OptionBox
        selected={sort === 'Risk Ranking'}
        onClick={() => setSort('Risk Ranking')}
      >
        Ranking (High-Low)
      </OptionBox>
    </div>
  </FilterSection>
)

const StatusOptions = ({
  status,
  setStatus,
}: {
  status: StatusOption
  setStatus: (status: StatusOption) => void
}) => (
  <FilterSection label="Status">
    <div className="flex flex-col">
      <OptionBox selected={status === ''} onClick={() => setStatus('')}>
        All
      </OptionBox>
      <OptionBox selected={status === '1'} onClick={() => setStatus('1')}>
        Approved
      </OptionBox>
      <OptionBox selected={status === '2'} onClick={() => setStatus('2')}>
        Not Approved
      </OptionBox>
      <OptionBox selected={status === '3'} onClick={() => setStatus('3')}>
        In Review
      </OptionBox>
    </div>
  </FilterSection>
)

const CommentOptions = ({
  includeComments,
  setIncludeComments,
}: {
  includeComments: boolean
  setIncludeComments: (include: boolean) => void
}) => (
  <FilterSection label="Comments">
    <div className="flex flex-col">
      <OptionBox
        selected={includeComments === true}
        onClick={() => setIncludeComments(true)}
      >
        Include
      </OptionBox>
      <OptionBox
        selected={includeComments === false}
        onClick={() => setIncludeComments(false)}
      >
        Exclude
      </OptionBox>
    </div>
  </FilterSection>
)

const RiskListOptions = ({
  selectedRiskLists,
  handleRiskListToggle,
  riskLists,
  riskListsLoading,
}: {
  selectedRiskLists: number[]
  handleRiskListToggle: (id: number) => void
  riskLists: RiskList[] | undefined
  riskListsLoading: boolean
}) => {
  const isAllRisksSelected = selectedRiskLists.length === 0

  return (
    <FilterSection label="Lists" maxHeight="40">
      {riskListsLoading ? (
        <div className="p-3 text-sm text-gray-500">Loading risk lists...</div>
      ) : (
        <>
          <OptionBox
            selected={isAllRisksSelected}
            onClick={() => handleRiskListToggle(-1)}
          >
            All Risks
          </OptionBox>
          {riskLists?.map((list) => (
            <OptionBox
              key={list.id}
              selected={selectedRiskLists.includes(list.id)}
              onClick={() => handleRiskListToggle(list.id)}
            >
              {list.name}
            </OptionBox>
          ))}
        </>
      )}
    </FilterSection>
  )
}

const ModalContent = ({
  format,
  setFormat,
  sort,
  setSort,
  status,
  setStatus,
  includeComments,
  setIncludeComments,
  selectedRiskLists,
  handleRiskListToggle,
  riskLists,
  riskListsLoading,
}: {
  format: ExportFormat
  setFormat: (format: ExportFormat) => void
  sort: SortOption
  setSort: (sort: SortOption) => void
  status: StatusOption
  setStatus: (status: StatusOption) => void
  includeComments: boolean
  setIncludeComments: (include: boolean) => void
  selectedRiskLists: number[]
  handleRiskListToggle: (id: number) => void
  riskLists: RiskList[] | undefined
  riskListsLoading: boolean
}) => (
  <div className="mt-4 space-y-4">
    <FormatOptions format={format} setFormat={setFormat} />
    <SortOptions sort={sort} setSort={setSort} />
    <StatusOptions status={status} setStatus={setStatus} />
    <CommentOptions
      includeComments={includeComments}
      setIncludeComments={setIncludeComments}
    />
    <RiskListOptions
      selectedRiskLists={selectedRiskLists}
      handleRiskListToggle={handleRiskListToggle}
      riskLists={riskLists}
      riskListsLoading={riskListsLoading}
    />
  </div>
)

const ModalFooter = ({
  onExport,
  onClose,
}: {
  onExport: () => void
  onClose: () => void
}) => (
  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
    <button
      type="button"
      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
      onClick={onExport}
    >
      Export
    </button>
    <button
      type="button"
      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
      onClick={onClose}
    >
      Cancel
    </button>
  </div>
)

const DialogPanelContent = ({
  format,
  setFormat,
  sort,
  setSort,
  status,
  setStatus,
  includeComments,
  setIncludeComments,
  selectedRiskLists,
  handleRiskListToggle,
  riskLists,
  riskListsLoading,
  onExport,
  onClose,
}: {
  format: ExportFormat
  setFormat: (format: ExportFormat) => void
  sort: SortOption
  setSort: (sort: SortOption) => void
  status: StatusOption
  setStatus: (status: StatusOption) => void
  includeComments: boolean
  setIncludeComments: (include: boolean) => void
  selectedRiskLists: number[]
  handleRiskListToggle: (id: number) => void
  riskLists: RiskList[] | undefined
  riskListsLoading: boolean
  onExport: () => void
  onClose: () => void
}) => (
  <Dialog.Panel className="relative min-w-[24rem] transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-[32rem] sm:max-w-xl">
    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
      <ModalTitle />
      <ModalContent
        format={format}
        setFormat={setFormat}
        sort={sort}
        setSort={setSort}
        status={status}
        setStatus={setStatus}
        includeComments={includeComments}
        setIncludeComments={setIncludeComments}
        selectedRiskLists={selectedRiskLists}
        handleRiskListToggle={handleRiskListToggle}
        riskLists={riskLists}
        riskListsLoading={riskListsLoading}
      />
    </div>
    <ModalFooter onExport={onExport} onClose={onClose} />
  </Dialog.Panel>
)

export default function ExportRiskReviewModal({ open }: { open: boolean }) {
  const dispatch = useDispatch()
  const posthog = usePostHog()
  const currentProject = useSelector(selectCurrentProject)
  const [format, setFormat] = useState<ExportFormat>('pdf')
  const [sort, setSort] = useState<SortOption>('A-Z')
  const [status, setStatus] = useState<StatusOption>('')
  const [includeComments, setIncludeComments] = useState(false)
  const [selectedRiskLists, setSelectedRiskLists] = useState<number[]>([])
  const [createRiskExport] = useCreateRiskExportMutation()

  useEffect(() => {
    if (open) {
      window.setTimeout(() => {
        const modalContent = document.querySelector('.overflow-y-auto')
        if (modalContent) {
          modalContent.scrollTop = 0
        }
      }, 0)
    }
  }, [open])

  const { data: riskLists, isLoading: riskListsLoading } =
    useGetRiskListFiltersQuery(currentProject?.id ?? skipToken)

  const handleRiskListToggle = (listId: number) => {
    if (listId === -1) {
      setSelectedRiskLists([])
      return
    }

    setSelectedRiskLists((prev) => {
      if (prev.length === 0) {
        return [listId]
      }

      return prev.includes(listId)
        ? prev.filter((id) => id !== listId)
        : [...prev, listId]
    })
  }

  const onClose = () => {
    dispatch(setModal(null))
  }

  const handleExport = () => {
    if (!currentProject?.uuid) return

    if (format === 'pdf') {
      posthog?.capture(POSTHOG.export_risk_pdf, {
        project_uuid: currentProject.uuid,
      })
    } else if (format === 'xlsx') {
      posthog?.capture(POSTHOG.export_risk_excel, {
        project_uuid: currentProject.uuid,
      })
    } else if (format === 'docx') {
      posthog?.capture(POSTHOG.export_risk_word, {
        project_uuid: currentProject.uuid,
      })
    }

    createRiskExport({
      projectId: currentProject.uuid,
      type: format,
      riskSort: sort,
      status: status,
      include_comments: includeComments,
      riskList: selectedRiskLists,
    })
      .unwrap()
      .then((response) => {
        if (response.export_url) {
          window.open(response.export_url, '_blank')
        }
      })

    toast.success('Risk Review export started')
    onClose()
  }

  return (
    <Transition.Root show={open} as="div">
      <Dialog as="div" className="relative z-[201]" onClose={onClose}>
        <Transition.Child
          as="div"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanelContent
                format={format}
                setFormat={setFormat}
                sort={sort}
                setSort={setSort}
                status={status}
                setStatus={setStatus}
                includeComments={includeComments}
                setIncludeComments={setIncludeComments}
                selectedRiskLists={selectedRiskLists}
                handleRiskListToggle={handleRiskListToggle}
                riskLists={riskLists}
                riskListsLoading={riskListsLoading}
                onExport={handleExport}
                onClose={onClose}
              />
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
