import React, { useCallback } from 'react'
import { FunnelIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Paper } from '@mantine/core'
import { useEffect, useRef, useState } from 'react'
import {
  setRiskListSearchQuery,
  setSelectedCategoryFilter,
  setSelectedDocuments,
  setSelectedRiskList,
} from '../../../../redux/risks-search-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectRiskWorkflowFilterState,
  setRiskWorkflowFilterState,
} from '../../../../redux/workflow-slice'
import { setDefaultPositionFilter } from '../../../../redux/application-slice'

interface SearchFiltersCardProps {
  defaultText?: string
  hoverText?: string
  panelWidth: number
}

export const SearchFiltersCard = ({
  defaultText = 'No Active Filters',
  hoverText = 'Clear All Filters',
  panelWidth,
}: SearchFiltersCardProps) => {
  const [isHovered, setIsHovered] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [width, setWidth] = useState('auto')
  const containerRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const riskWorkflowFilterState = useSelector(selectRiskWorkflowFilterState)

  useEffect(() => {
    if (containerRef.current) {
      setWidth(`${containerRef.current.offsetWidth}px`)
    }
  }, [])

  // This is an exact copy of the handleResetSortAndFilter in risk-review-page.tsx
  const handleResetSortAndFilter = useCallback(() => {
    const { status, sort, ...rest } = riskWorkflowFilterState
    dispatch(
      setRiskWorkflowFilterState({
        status: undefined,
        sort: '',
        ...rest,
      })
    )
  }, [dispatch, riskWorkflowFilterState])

  // This is an exact copy of the handleResetAllFilters in risk-review-page.tsx
  const handleResetAllFilters = () => {
    dispatch(setDefaultPositionFilter(null))
    dispatch(setRiskListSearchQuery(''))
    dispatch(setSelectedRiskList(null))
    dispatch(setSelectedDocuments([]))
    dispatch(setSelectedCategoryFilter(null))
    handleResetSortAndFilter()
  }

  // TODO Use Panel Width
  return (
    <Paper
      ref={containerRef}
      className={`cursor-pointer !rounded-lg px-2 py-2 transition-all duration-300 ease-in-out
        ${isHovered ? 'border !border-red-400 !bg-red-50' : 'border !bg-[#e5e7eb]'}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleResetAllFilters}
    >
      <div className="flex items-center justify-center">
        <div className="relative h-5 w-5 flex-shrink-0">
          <FunnelIcon
            className={`absolute inset-0 text-gray-500 transition-all duration-300  ease-in-out
              ${isHovered ? ' scale-75 opacity-0' : 'scale-100 text-gray-500 opacity-100'}`}
          />
          <TrashIcon
            className={`absolute inset-0 text-red-500 transition-all duration-300 ease-in-out
              ${isHovered ? 'scale-100 opacity-100' : 'scale-75 opacity-0'}`}
          />
        </div>
        <div className={`relative ml-1 flex-1 overflow-hidden font-[600]`}>
          <div
            className={`whitespace-nowrap transition-all duration-300 ease-in-out
            ${isHovered ? 'translate-y-full opacity-0' : 'translate-y-0 text-gray-500  opacity-100'}`}
          >
            <span className={`text-wrap text-sm font-normal`}>
              {defaultText}
            </span>
          </div>
          <div
            className={`absolute inset-0 flex items-center whitespace-nowrap transition-all duration-300 ease-in-out
            ${isHovered ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'}`}
          >
            <span className={`text-wrap text-sm font-normal text-red-500`}>
              {hoverText}
            </span>
          </div>
        </div>
      </div>
    </Paper>
  )
}
