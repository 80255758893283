import React from 'react'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../../redux/application-slice'
import { MODAL_TYPES } from '../../../modals/modal-controller'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { ProjectRisk } from '../../../../shared/interfaces/project/risk/risk-inteface'
import { Paper, Text } from '@mantine/core'
import clsx from 'clsx'

export const ShareCardPopover = ({
  projectRisks,
  riskListSearchQuery,
  risksToDisplay,
}: {
  projectRisks: ProjectRisk[]
  riskListSearchQuery: string
  risksToDisplay: ProjectRisk[]
}) => {
  const dispatch = useDispatch()
  const nothingToExport =
    riskListSearchQuery !== '' && risksToDisplay.length === 0

  const handleExportClick = () => {
    dispatch(setModal({ modal: MODAL_TYPES.EXPORT_RISK_REVIEW }))
  }

  return (
    <div className="cursor-pointer">
      <Paper
        onClick={handleExportClick}
        className={clsx('group !rounded-lg border p-2.5 transition-colors', {
          'hover:!border-blue-400 hover:!bg-blue-100 hover:bg-gray-50 active:border-blue-500 active:!bg-white':
            !nothingToExport,
        })}
        styles={() => ({
          root: {
            backgroundColor: '#e5e7eb',
            cursor: nothingToExport ? 'default' : 'pointer',
          },
        })}
      >
        <div className="flex items-center justify-center">
          <div
            className={clsx('flex text-gray-500', {
              'group-hover:text-blue-600': !nothingToExport,
            })}
          >
            <DocumentArrowDownIcon className="h-5 w-5" />
            <Text p="0" size="sm" fw={400} ml="4px" c="currentColor">
              Export
            </Text>
          </div>
        </div>
      </Paper>
    </div>
  )
}
